import React from 'react';
import Fade from 'react-reveal/Fade';

const HoopsIntro = () => {
  return (
    <Fade delay={500} duration={2000}>
      <section className="font-light mt-16 items-center pb-16 border-b border-gray-200 h-full">
        <section className="w-full text-2xl sm:text-3xl">
          <h3 className="py-1">Hello. I'm <b>Nolan Knill</b>, Founder and Lead Trainer of <b>Peak Hoops.</b></h3>
          <h3 className="mt-6">I have played and coached basketball at the CCAA level in Canada, and managed basketball at the NCAA D1 level in the United States. I am now training girls and boys of all-ages in the GTA to help them reach their hoop dreams.</h3> 
        </section>
      </section>
    </Fade>
  );
};

export default HoopsIntro;