import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Socials = ({ socials }) => {
  return (
    <section className="flex mt-8 justify-center">
      {socials.map((social, index) => {
        return (
          <a
            key={index}
            aria-label={social.label}
            href={social.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon
              icon={social.icon}
              className="text-gray-400 hover:text-gray-700 dark:hover:text-white mx-4 text-4xl"
            />
          </a>
        );
      })}
    </section>
  );
};

export default Socials;
