import React from 'react';

const WebDevBio = () => {
  return (
    <section className="about">
      <h3>About Me</h3>
      <p>
      I have been in the industry for close to ten years, working as a full-stack developer with a back-end specialization. I love trying out new languages and frameworks that power the web, with a passion for best practices and clean code. I hope to pass on my passion and knowledge to the next generation of developers.
      </p>
      <p>
      I teach at Brainstation and if you want to learn more about our immersive bootcamp: 
      </p>
      <a className="button" href="https://brainstation.io/course/toronto/web-development-immersive">Click Here</a>
    </section>
  );
};

export default WebDevBio;