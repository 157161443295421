import React, { useEffect } from 'react';
import Header from "./Header";
import Hero from "./Hero";
import Jobs from "./Jobs";
import Nav from "./Nav";
import Socials from "./Socials";
import WebDevBio from "./WebDevBio";
import WebDevIntro from "./WebDevIntro";
import Technologies from "./Technologies";
import HeroImage from "../images/toronto.jpg";
import { faInstagram, faLinkedin, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";

const WebDev = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const socials = [
    {
      label: "Instagram",
      icon: faInstagram,
      link: "https://instagram.com/nol14k"
    },
    {
      label: "LinkedIn",
      icon: faLinkedin,
      link: "https://linkedin.com/in/nolanknill/"
    },
    {
      label: "GitHub",
      icon: faGithub,
      link: "https://github.com/nolanknill"
    },
    {
      label: "Twitter",
      icon: faTwitter,
      link: "https://twitter.com/nolanknill"
    }
  ];
  return (
    <main className="main">
      <Header />
      <Nav />
      <Hero
        imageSrc={HeroImage} 
        imageAlt="Toronto Cityscape"
      />
      <article className="container">
        <WebDevIntro />
        <Technologies />
        {/* <Jobs />
        <WebDevBio />
        */ }
      </article>
      <Nav />
      <Socials socials={socials}/>
    </main>
  );
};

export default WebDev;