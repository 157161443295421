import React, { useEffect } from 'react';
import Header from "./Header";
import Hero from "./Hero";
import HoopsIntro from "./HoopsIntro";
import Nav from "./Nav";
import Socials from "./Socials";
import HeroImage from "../images/hoops.jpg";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";


const Hoops = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const socials = [
    {
      label: "Instagram",
      icon: faInstagram,
      link: "https://instagram.com/peakhoopstoronto"
    },
    {
      label: "Twitter",
      icon: faTwitter,
      link: "https://twitter.com/nolanknill"
    }
  ];

  return (
    <main className="main dark bg-black text-white min-h-screen min-w-full">
      <Header />
      <Nav />
      <Hero 
        imageSrc={HeroImage} 
        imageAlt="Nolan holding ball on court with Toronto skyline in background"
      />
      <article className="container">
        <HoopsIntro />
      </article>
      <Nav />
      <Socials socials={socials}/>
    </main>
  );
};

export default Hoops;