import React from 'react';

const Hero = ({imageSrc, imageAlt}) => {
  return (
    <section className="-mx-2 mt-16 w-screen">
      <img className="h-64 sm:h-96 w-full object-cover" src={imageSrc} alt={imageAlt}/>
    </section>
  );
};

export default Hero;