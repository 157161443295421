import React from 'react';

const Header = () => {
  return (
    <header className="uppercase text-center">
      <h1 className="mt-10 tracking-wide text-4xl">Nolan Knill</h1>
    </header>
  );
};

export default Header;