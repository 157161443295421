import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import WebDev from "./components/WebDev";
import Hoops from "./components/Hoops";
import "./index.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <Route exact path="/" component={WebDev} />
                <Route exact path="/hoops" component={Hoops} />
              </Switch>
            );
          }}
        />
      </BrowserRouter>
    );
  }
}

export default App;
