import React from 'react';
import Headshot from "../images/headshot.jpg";
import Fade from 'react-reveal/Fade';

const WebDevIntro = () => {
  return (
    <section className="font-light mt-16 items-center pb-16 border-b border-gray-200 h-full">
      <Fade delay={500} duration={2000}>
        <section className="w-full text-2xl sm:text-3xl">
          <p className="py-1">Hello. I'm <b>Nolan Knill</b>, Lead Educator of the Web Development Bootcamp at <a className="font-medium" href="https://brainstation.io/" style={{color:"#1358db"}}>BrainStation</a>. As an educator, I am excited to share my ten years of experience in web development with the next generation of developers.
          </p>
        </section>
      </Fade>
      <Fade delay={500} duration={2000}>
        <section className="flex-col mt-12 w-full h-full min-w-64 flex justify-center items-center">
          <img className="shadow-2xl border-2 border-gray-400 rounded-full sm:w-64 sm:h-64 lg:w-96 lg:h-96 w-48 h-48 object-cover" src={Headshot} alt="headshot"/>
          <p className="mt-6">My Last Professional Headshot (2019)</p>
        </section>
      </Fade>
    </section>
  );
};

export default WebDevIntro;