import React from 'react';
import {NavLink} from 'react-router-dom';

const Nav = () => {
  return (
    <nav className="nav">
      <div className="mr-2 sm:mr-12">
        <NavLink className="hover:text-gray-800 dark:hover:text-white" to="/" exact activeClassName="dark:text-white text-gray-800">Web Development</NavLink>
      </div>
      <div className="ml-2 sm:ml-12">
        <NavLink className="hover:text-gray-800" to="/hoops" exact activeClassName="dark:text-white text-gray-800">Basketball</NavLink>
      </div>
    </nav>
  );
};

export default Nav;