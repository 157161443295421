import React from "react";
import Fade from "react-reveal/Fade";

const Technologies = () => {
  const links = [
    {
      label: "HTML5",
      icon: "devicon-html5-plain-wordmark",
      color: "html5",
      link: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    },
    {
      label: "CSS3",
      icon: "devicon-css3-plain-wordmark",
      color: "css3",
      link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    },
    {
      label: "Javascript",
      icon: "devicon-javascript-plain",
      color: "javascript",
      link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    },
    {
      label: "React JS",
      icon: "devicon-react-original-wordmark",
      color: "react",
      link: "https://reactjs.org/",
    },
    {
      label: "Node JS",
      icon: "devicon-nodejs-plain-wordmark",
      color: "nodejs",
      link: "https://nodejs.org/en/",
    },
    {
      label: "Express JS",
      icon: "devicon-express-original",
      color: "black",
      link: "https://expressjs.com/",
    },
    {
      label: "MySQL",
      icon: "devicon-mysql-plain-wordmark",
      color: "mysql",
      link: "https://mysql.com",
    },
  ];

  return (
    <section className="mt-20 border-b border-gray-200 pb-16">
      <Fade delay={500} duration={2000}>
        <p className="mb-8 sm:mb-16 font-light text-2xl sm:text-3xl">
          I teach the following{" "}
          <b className="font-medium">cutting-edge web technologies</b> to
          prepare students for employment in just 12 weeks. My goal is to ignite
          my students' passion for learning and ensure they have the necessary
          skills to become <b className="font-medium">future-proof</b>.
        </p>
      </Fade>
      <Fade delay={500} duration={2000}>
        <section className="mb-4 flex flex-wrap flex-row justify-center sm:justify-around">
          {links.map((link, index) => {
            return (
              <a
                key={index}
                aria-label={link.label}
                className={
                  "transition-colors duration-300 text-black mx-4 sm:mx-4 my-4 sm:my-6 px-12 py-8 border-2 border-gray-300 rounded-lg shadow-xl hover:text-" +
                  link.color +
                  " hover:border-" +
                  link.color
                }
                href={link.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className={"text-8xl " + link.icon}></i>
              </a>
            );
          })}
        </section>
      </Fade>
    </section>
  );
};

export default Technologies;
